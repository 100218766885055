$(document).ready(function(){
    $('.home-carousel').slick({
        // fade: true,
        arrows: true,
        dots: true,
        // autoplay: true,
        // autoplaySpeed: 1500,
    });

    $('.profit-carousel').slick({
        dots: true,
        arrows: true,
        responsive: [{
            breakpoint: 480,
            settings: {
                arrows: false
            }
        }]
    });

    $('.testimonial-slider').slick({
        dots: true,
        arrows: false,
    });

    var instructionSlides = JSON.parse($('input[name=instructionSlides]').val());
    var instructionImages = instructionSlides.map(slide => slide.image && slide.image.length > 0 ? slide.image : "/Assets/Images/Elements/no-img.png");

    $(".changeIns").click(function(){
        var thisIns = $(this);
        $('.ins-steps').find('*').removeClass("active");
        thisIns.find(".dots").addClass("active");
        thisIns.find(".step-text").addClass("active");
        var stepText = thisIns.find(".step-text").text();
        $(".step-text-mobile").text(stepText);

        var number = Number(thisIns.find('.dots').text());
        $('.ip-step img').attr("src", '/savy' +  instructionImages[number - 1]);
    });

    $('.counter').counterUp({
        delay: 2,
        time: 1000
    });

    // $('#home-stats').focus(function() {
    //     console.log("Duy");
    // })
    // var offsetToHomeStats = $('#home-stats').offset().top;

    // var scrollCounter = function () {
    //     if (offsetToHomeStats - $(window).scrollTop() <= 200) {
    //         $(window).off('scroll', scrollCounter);
    //         console.log("Duy");
    //         const figuresList = JSON.parse($("input[name=figuresList]").val());
    //         var comma_separator_number_step = $.animateNumber.numberStepFactories.separator('.')

    //         for (var i = 0; i < figuresList.length; i++) {
    //             const figure = figuresList[i];

    //             $(`#stat-${i}`).animateNumber({ 
    //                 number: figure.value,
    //                 numberStep: comma_separator_number_step
    //             }, 1500);
    //         }
    //     }
    // }

    // $(window).scroll(scrollCounter);
});